<template>
	<Loading v-show="loading && !props.hideLoading" />
	<GeolocationWarnings :granted="granted" :support="support" class="mb-6" />
	<div class="relative h-full w-full p-3">
		<Banner v-if="success" :open="true" type="success" class="mb-3">{{ success }}</Banner>
		<DealAction v-if="deal && action" :action="action" :deal="deal" :project="props.project" @abort="onAbort" @success="onSuccess" />
		<div v-if="deal" class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0">
			<div class="sticky top-14 bg-white z-10">
				<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
					<a v-if="deal.loc" :href="`https://www.google.com/maps/place/${deal.loc.coordinates[1]},${deal.loc.coordinates[0]}`" target="_blank" rel="noopener noreferrer" class="text-light-blue-500 underline">
						<button class="btn bg-green-600 hover:bg-green-500 text-white mr-1">
							<icon name="ri-road-map-line" class="opacity-70 w-5 h-5 mr-1" />
							Google Maps
						</button>
					</a>
				</div>
			</div>
			<div class="grow bg-gray-100">
				<DealEnergyDetails v-if="!isFinance" :deal="deal" :distance="props.distance || distance" :agent="agent" />
				<DealFinanceDetails v-if="isFinance" :deal="deal" :distance="props.distance || distance" :agent="agent" />
				<DealActionsPartial :deal="deal" @action="onAction" :disableNewVisitReported="disableNewVisitReported" />
				<DealShortcutsPartial :deal="deal" :project="props.project" />
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'
import Banner from '../../components/Banner.vue'
import Button from '../../components/Button.vue'
import DealActionsPartial from './DealActionsPartial.vue'
import DealShortcutsPartial from './DealShortcutsPartial.vue'
import { Deal } from '../../interfaces/Deal'
import { User } from '../../interfaces/User'
import DealAction from '../../partials/deal/DealAction.vue'
import DealFinanceDetails from '@/apps/go/components/Contract/partials/finance/DealFinanceDetails.vue'
import DealEnergyDetails from '@/apps/go/components/Contract/partials/finance/DealEnergyDetails.vue'
import { DealAction as DealActionInterface } from '../../interfaces/DealAction'
import { get } from '../../services/api'
import Loading from '../../components/Loading.vue'
import { calculateDistance } from '../../utils/calculateDistance'
import GeolocationWarnings from '@/apps/go/components/geolocation/GeolocationWarnings.vue'
import { user } from '@/user'

const props = defineProps<{ _id: string; project: string; distance?: number; hideLoading?: boolean }>()
const isFinance = import.meta.env.VITE_PRODUCT == 'finance'
const errors = ref([])
const success = ref(null)
const action = ref<DealActionInterface | null>(null)
const deal = ref<Deal | null>(null)
const agent = ref<User | null>(null)
const loading = ref(false)
const emit = defineEmits(['action', 'fetchStart', 'fetchEnd'])
const geolocationWatcher = ref(null)
const distance = ref(0)
const disableNewVisitReported = ref(true)
const fetchLocation = ref(false)
const granted = ref(true)
const support = ref(true)

const onSuccess = () => {
	action.value = null
	success.value = 'Actualizado corréctamente'
	fetch()
}

const onAbort = () => {
	action.value = null
	success.value = null
}

const onAction = async (toAction?: DealActionInterface) => {
	errors.value = []
	action.value = toAction
}

const fetch = async () => {
	emit('fetchStart')
	deal.value = null
	loading.value = true
	const { data } = await get({ name: 'deal', params: { project: props.project, _id: props._id } })
	if (data.user) {
		const agentResponse = await get({ name: 'user', params: { _id: data.user } })
		agent.value = agentResponse.data
	}
	deal.value = data
	if (navigator.geolocation) {
		initGeolocationWatcher()
	} else support.value = false
	loading.value = false
	emit('fetchEnd', deal.value)
}

const initGeolocationWatcher = () => {
	geolocationWatcher.value = navigator.geolocation.watchPosition(
		({ coords }) => {
			if (deal.value && deal.value.loc && deal.value.loc.coordinates) {
				distance.value = calculateDistance(coords.latitude, coords.longitude, deal.value.loc.coordinates[1], deal.value.loc.coordinates[0])
				fetchLocation.value = true
			}
		},
		onGeolocationErrorCallback,
		{
			enableHighAccuracy: false,
			timeout: 10000
		}
	)
}

const clearWatch = () => {
	if (geolocationWatcher.value) {
		navigator.geolocation.clearWatch(geolocationWatcher.value)
		geolocationWatcher.value = null
	}
}

const onGeolocationErrorCallback = (error: any) => {
	distance.value = 0
	fetchLocation.value = false
	switch (error.code) {
		case error.PERMISSION_DENIED:
			granted.value = false
			clearWatch()
			break
		case error.POSITION_UNAVAILABLE:
		case error.TIMEOUT:
		case error.UNKNOWN_ERROR:
			console.error(error)
			break
	}
}

onMounted(async () => {
	fetch()
})

onUnmounted(() => {
	clearWatch()
})

watch(action, () => {
	if (geolocationWatcher.value) {
		clearWatch()
	} else if (navigator.geolocation) {
		initGeolocationWatcher()
	}
})

watch(
	() => props._id,
	_id => {
		if (props._id) {
			fetch()
		} else {
			clearWatch()
		}
	}
)

watch(distance, () => {
	disableNewVisitReported.value = fetchLocation.value && distance.value > (user.value.config.maxMetersToReport || 0)
})
</script>
