<template>
	<Loading v-show="loading" />

	<div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="!loading">
		<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
		<div class="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
			<div class="flex min-h-full items-end justify-center text-center items-center xs:p-1">
				<div class="relative transform rounded-sm bg-white text-left shadow-xl transition-all w-full md:w-4/6">
					<div class="bg-gray-50 px-4 py-3 border-b">
						<h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{{ form?.title || props.action.label }}</h3>
					</div>
					<GeolocationWarnings :granted="granted" :support="support" class="mb-6" />
					<div v-if="support && granted">
						<div v-if="deals" class="p-3">
							<div v-for="(deal, i) in deals">
								<span class="rounded-full text-center px-2.5 py-0.5 bg-green-100 text-green-600 text-xs">{{ deal.user.name }} {{ deal.user.surname }} - {{ deal.contract.customer.name }} {{ deal.contract.customer.lastname }} </span> <span v-show="!!getErrorMessage(errors, `deals[${i}].options`)" class="inline-flex font-xs m-1 rounded-full text-center px-2.5 py-0.5 bg-red-100 text-red-600">{{ getErrorMessage(errors, `deals[${i}].options`)?.message }}</span>
							</div>
						</div>
						<div v-if="!action.form && action.type == 'updateStatus'" class="bg-white p-4">
							Vas a cambiar la oportunidad al estado <span class="font-bold">{{ action.data.label }}</span
							>. ¿Estás seguro?
						</div>
						<div v-if="!action.form && action.type != 'updateStatus'" class="bg-white p-4">Vas a {{ action.label.toLowerCase() }}. ¿Estás seguro?</div>
						<div v-if="action.form && form" class="bg-white p-4">
							<div class="grid gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
								<template v-for="(item, i) in form.items" :key="i">
									<div v-if="types?.includes(item.type)" v-show="show[item.name]" class="flex flex-col" :class="{ 'col-span-full ': ['textarea', 'warning', 'info', 'file']?.includes(item.type) }" :key="i">
										<label v-if="item.label" :for="item.name" class="mb-2" :class="{ 'min-h-[22px]': !item.label }"> {{ item.label }} <span class="text-red-500" v-if="!item.rules ? false : !!!item.rules.optional">*</span></label>
										<div v-if="item.type === 'warning'" class="flex items-center">
											<Banner :open="true" type="warning" class="w-full" :fixed="true">
												<span>{{ evalContent(item.content) }}</span>
											</Banner>
										</div>
										<div v-if="item.type === 'info'" class="flex items-center">
											<Banner :open="true" type="info" class="w-full" :fixed="true">
												<span>{{ evalContent(item.content) }}</span>
											</Banner>
										</div>
										<Multiselect v-if="['user', 'users']?.includes(item.type)" :name="item.name" :id="item.name" v-model="selectedUsers" track-by="_id" :custom-label="u => `${u.name} ${u.surname}`" placeholder="Seleccionar usuario" :closeOnSelect="true" :options="users" :showNoResults="true" :clearOnSelect="false" :preserveSearch="true" :hideSelected="item.type === 'users'" :multiple="item.type === 'users'" selectLabel="enter para seleccionar" selectedLabel="seleccionado" deselectLabel="enter para remover" @select="onSelectUser" @remove="onSelectUser">
											<template #noResult>Sin resultados. Realiza otra búsqueda. </template>
											<template #noOptions>No se han encontrado usuarios. </template>
										</Multiselect>

										<!-- SELECT -->
										<select v-if="item.type === 'select'" :name="item.name" :id="item.name" v-model="data[item.name]" :required="!!!item.optional" :class="{ 'border border-red-600': !!getErrorMessage(errors, `options.${item.name}`) }">
											<option :value="undefined">{{ item.placeholder || 'Seleccionar' }}</option>
											<option v-for="(opt, i) in item.options" :key="i" :value="opt.value">{{ opt.label || opt.value }}</option>
										</select>

										<!-- CHECKBOX -->
										<div v-if="item.type === 'checkbox'" class="flex items-center h-full">
											<input :type="item.type" class="form-checkbox" :name="item.name" :id="item.name" v-model="data[item.name]" :required="!!!item.optional" />
											<label :for="item.name" class="ml-1">{{ item.placeholder }}</label>
										</div>
										<!-- TEXTAREA -->
										<textarea class="form-input" v-if="item.type === 'textarea'" :placeholder="item.placeholder" :name="item.name" :id="item.name" :cols="item.cols || 10" :rows="item.rows || 3" v-model="data[item.name]" :required="!!!item.optional" :class="{ 'border border-red-600': !!getErrorMessage(errors, `options.${item.name}`) }"></textarea>
										<!-- DATETIME -->
										<template v-if="['datetime', 'datetime-local']?.includes(item.type)">
											<input class="form-input" type="datetime-local" :name="item.name" :id="item.name" v-model="data[item.name]" :min="item.min" :max="item.max" :required="!!!item.optional" :class="{ 'border border-red-600': !!getErrorMessage(errors, `options.${item.name}`) }" />
											<small>{{ item.placeholder }}</small>
										</template>
										<!-- DATE, TEXT, TIME, NUMBER -->
										<input class="form-input" v-if="['text', 'date', 'time', 'number'].includes(item.type)" :type="item.type" :name="item.name" :id="item.name" v-model="data[item.name]" :min="item.min" :max="item.max" :required="!!!item.optional" :class="{ 'border border-red-600': !!getErrorMessage(errors, `options.${item.name}`) }" />

										<!-- INPUT FILE -->
										<InputFile v-if="item.type == 'file'" @update:model-value="updateFile" :accept="item.accept" :modelValue="data[item.name]" :required="!!!item.optional" :errors="error?.errors || []" :multiple="item.multiple"> </InputFile>

										<!-- Error message -->
										<p class="text-red-600">
											<small>{{ getErrorMessage(errors, `options.${item.name}`)?.message }}</small>
										</p>
									</div>
								</template>
							</div>
						</div>
						<div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
							<Button class="ml-2" type="submit" @click="executeAction(props.action)" :loading="loading" :disabled="loading || (!deal && !deals?.length)">
								<icon name="ri-check-line" class="mr-1" />
								Aceptar
							</Button>
							<Button color="tertiary" @click="$emit('abort')">
								<icon name="ri-close-line" class="mr-1" />
								Cancelar
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import Loading from '@/apps/go/components/Loading.vue'
import Banner from '@/apps/go/components/Banner.vue'
import { get, put } from '@/apps/go/services/api'
import { ValidationError } from '@/errors/ValidationError'
import { onMounted, ref, watch } from 'vue'
import { user } from '@/user'
import { DealAction as DealActionInterface } from '../../interfaces/DealAction'
import { Deal } from '@/apps/go/interfaces/Deal'
import { validator } from '@/services/validator'
import Multiselect from 'vue-multiselect'
import InputFile from '@/apps/go/components/inputFile/InputFile.vue'
import Button from '@/apps/go/components/Button.vue'
import GeolocationWarnings from '@/apps/go/components/geolocation/GeolocationWarnings.vue'

const props = defineProps<{ deal?: Deal; deals?: Deal[] | any[]; action: DealActionInterface; project: string; bulk?: boolean }>()
const emit = defineEmits(['success', 'error', 'abort'])
const show = ref(<Record<string, boolean>>{})
const form = ref(null)
const loading = ref(false)
const error = ref<ValidationError>(<any>{})
const types = ['select', 'text', 'date', 'time', 'textarea', 'datetime', 'datetime-local', 'number', 'checkbox', 'warning', 'info', 'user', 'users', 'file']
const selectedUsers = ref(null)
const data = ref({})
const users = ref([])
const errors = ref([])
const support = ref(true)
const granted = ref(true)

onMounted(async () => {
	if (props.action.form) {
		await fetchForm()
		evalFormItemsShow()
		if (!navigator.geolocation) {
			support.value = false
		}
		navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {
			permissionStatus.state == 'granted' ? (granted.value = true) : (granted.value = false)
			permissionStatus.onchange = function () {
				this.state == 'granted' ? (granted.value = true) : (granted.value = false)
			}
		})
	}
})

const evalFormItemsShow = async () => {
	for (const item of form.value.items) {
		if (!item.eval) {
			show.value[item.name] = true
		} else {
			let errors = []
			const dataSchema = { user, deal: props.deal || {}, form: data.value }
			for (const key in item.eval) {
				if (!errors.length) {
					errors = await validator.validate(dataSchema[key], item.eval[key])
				}
			}
			show.value[item.name] = !errors.length
		}
	}
}

const onSelectUser = () => {
	const inputName = form.value.items.find(item => ['user', 'users']?.includes(item.type)).name || ''
	const selected = Array.isArray(selectedUsers.value) && selectedUsers.value.length ? selectedUsers.value.map(user => user._id) : selectedUsers.value._id ? [selectedUsers.value._id] : undefined
	data.value[inputName] = selected?.length ? selected.join(',') : undefined
}

const fetchForm = async () => {
	loading.value = true
	form.value = null
	try {
		const { data } = await get({ name: 'action.form', params: { action: props.action._id } })
		form.value = data
		if (form.value.items.find(item => ['user', 'users']?.includes(item.type))) {
			fetchUsers()
		}
	} catch (err) {
		alert(err)
	}
	loading.value = false
}

const fetchUsers = async () => {
	try {
		users.value = []
		const apiQuery = { projects: props.project, limit: '5999', active: 'true', sort: 'name,surname', projection: 'name,surname' }
		const { data } = await get({ name: 'user', query: apiQuery })
		users.value = data
	} catch (err) {
		alert(err)
	}
}

const executeAction = async (action: DealActionInterface) => {
	loading.value = true

	navigator.geolocation.getCurrentPosition(
		async ({ coords }) => {
			const options = { ...data.value, coordinates: [coords.longitude, coords.latitude] }

			try {
				if (action.type === 'updateStatus') {
					if (props.deal) {
						await put({ name: 'deal.change.status', params: { project: props.project, _id: props.deal._id } }, { type: action.data.type, options }, true)
					} else {
						alert('Error, no puedes cambiar el estado a múltiples deals')
					}
				} else {
					if (props.deal) {
						await put({ name: 'deal.action', params: { project: props.project, _id: props.deal._id } }, { type: action.type, options }, true)
					} else if (props.bulk && props.deals) {
						await put({ name: 'deal.action.bulk', params: { project: props.project } }, { type: action.type, options, deals: props.deals.map(item => item._id) }, true)
					} else {
						console.error('Error al antes de lanzar una deal action')
					}
				}
				loading.value = false
				emit('success')
			} catch (error) {
				loading.value = false
				if (error instanceof ValidationError) {
					errors.value = error.errors || []
				} else {
					throw error
				}
			}
		},
		error => {
			loading.value = false
			alert('Error al intentar obtener tu ubicación')
			granted.value = false
		},
		{
			timeout: 5000,
			maximumAge: 60000,
			enableHighAccuracy: true
		}
	)
}

const getErrorMessage = (errors, prefix) => {
	return errors.find(error => error.field.startsWith(prefix))
}

watch(data.value, () => {
	evalFormItemsShow()
})

const updateFile = files => {
	// TODO: check
	// data.value.file = files.map(item => item.file)
}

const evalContent = content => {
	const deal = props.deal
	return content.startsWith('`') ? eval(content) : content
}
</script>
