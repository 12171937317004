<template>
	<Default :loading="loading">
		<div class="w-full h-full relative">
			<div class="flex flex-col">
				<div class="sticky top-14 bg-white z-10">
					<div class="flex items-center justify-between border-b border-gray-200 px-4 sm:px-6 md:px-5 h-16">
						<div class="flex">
							<Button color="tertiary" @click="back">
								<icon name="ri-arrow-left-line" class="mr-1" />
								Volver
							</Button>
						</div>
						<div class="flex items-center gap-2" v-if="payload">
							<ButtonDropdown title="Descargar plantilla" iconLeft="ri-download-2-line">
								<div class="grid gap-2">
									<div>
										<label class="block text-sm font-medium mb-1">Descargar plantilla para </label>
										<select v-model="selectedAction" class="form-select w-full">
											<option :value="undefined" disabled selected>Selecciona acción</option>
											<option v-for="permission in permissions" :key="permission.key" :value="permission.key">
												{{ permission.name }}
											</option>
										</select>
									</div>

									<Button :disabled="loadingDownload || !!!selectedAction" size="sm" color="primary" :loading="loadingDownload" @click="downloadTemplate" loadingLabel="...Descargando plantilla">
										<icon name="ri-dowload-2-line" class="mr-1" />
										Descargar
									</Button>
								</div>
							</ButtonDropdown>

							<Button :loading="loading" @click="importUpdateLeadFile" :disabled="loading || !payload.project || !!!payload.files?.length" loadingLabel="...Cargando Leads">
								<icon name="ri-save-2-line" class="mr-1" />
								{{ selectedAction === 'lead.file.import' ? 'Importar' : 'Actualizar' }}
							</Button>
						</div>
					</div>
				</div>

				<div class="w-full h-full relative grow p-3 bg-gray-100" v-if="payload">
					<Banner v-if="selectedAction" type="success" class="mb-3" :open="isSaved" @onClose="onCloseBanner"> Leads {{ selectedAction === 'lead.file.import' ? 'importados' : 'actualizados' }} correctamente </Banner>

					<form @submit.prevent>
						<div class="bg-white rounded-sm border border-gray-200 mb-3">
							<!-- Body -->
							<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200">
								<icon name="ri-shield-line" class="text-gray-400 mr-1" />
								<div>Adjuntar archivo</div>
							</div>

							<div class="text-sm text-gray-800 p-5">
								<div>
									<div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
										<div v-if="permissions.length > 1">
											<label class="block text-sm font-medium mb-1" for="action">Acción <span class="text-red-500">*</span></label>
											<select name="action" v-model="selectedAction" class="form-select w-full">
												<option disabled selected :value="undefined">Selecciona acción</option>
												<option v-for="permission in permissions" :key="permission.key" :value="permission.key">
													{{ permission.name }}
												</option>
											</select>
										</div>
										<div>
											<label class="block text-sm font-medium mb-1" for="project">Proyecto <span class="text-red-500">*</span></label>
											<select name="project" v-model="payload.project" class="form-select w-full">
												<option :value="undefined">Selecciona Proyecto</option>
												<option v-for="project in projects" :key="project._id" :value="project.name">
													{{ project.label }}
												</option>
												<p class="text-red-600">
													<small>{{ getErrorMessage('project') }}</small>
												</p>
											</select>
										</div>
										<div class="col-span-full">
											<label class="block text-sm font-medium mb-1" for="label">Carga masiva de Oportunidades<span class="text-red-500">*</span></label>
											<!-- <small>Los archivos permitidos son con las siguientes extensiones: .txt, .xlsx, .xls, .csv</small> -->
											<small>Los archivos permitidos son con las siguientes extensiones: .xlsx</small>
											<p :class="getErrorMessage('file') && 'text-red-600'">
												<InputFile @update:model-value="updateModelValue" :accept="['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']" :modelValue="payload.files" required :errors="error?.errors || []"> </InputFile>
												<small>{{ getErrorMessage('file') }}</small>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="error && error.errors" class="mt-4 bg-red-100 border-t-4 border-red-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
							<div class="flex">
								<div class="py-1">
									<svg class="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg>
								</div>
								<div>
									<p class="font-bold">Se han detectado {{ error.errors.length }} error(es)</p>
									<p v-if="selectedAction" class="text-sm">Revisa los errores para poder continuar con la {{ selectedAction === 'lead.file.import' ? 'importación' : 'actualización' }}.</p>
									<ul class="text-sm">
										<li v-for="item in error.errors">
											<strong>{{ item.field }}</strong> - {{ item.message }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</Default>
</template>

<script setup lang="ts">
import Banner from '@/apps/go/components/Banner.vue'
import { ValidationError } from '@/errors/ValidationError'
import { dayjs } from '@/services/dayjs'
import { user } from '@/user'
import { Ref, onMounted, reactive, ref } from 'vue'
import Button from '../../components/Button.vue'
import ButtonDropdown from '../../components/ButtonDropdown.vue'
import InputFile from '../../components/inputFile/InputFile.vue'
import { Project } from '../../interfaces/Project'
import { get, post, put } from '../../services/api'
import { useValidationErrorsMessages } from '../../utils/useValidationErrorsMessages'
import Default from '../Default.vue'

const error = ref<ValidationError>(null)
const getErrorMessage = useValidationErrorsMessages(error)
const projects: Ref<Project[]> = ref([])

const loading = ref(false)
const loadingDownload = ref(false)
const saving = ref(false)
const isSaved = ref(false)

const templateTypes = {
	txt: 'Texto .txt',
	csv: 'CSV .csv',
	xlsx: 'Excel .xlsx',
	xls: 'excel .xls'
}
const templateType = ref('xlsx')

let payload = reactive<{
	files: Record<string, any>[]
	project: string
}>(<any>{})

const permissions = ref(
	user.value.permissions
		.filter(p => p === 'lead.file.import' || p === 'lead.file.update')
		.map(p => ({
			key: p,
			name: p === 'lead.file.import' ? 'Importar leads' : 'Actualizar leads'
		}))
)

const selectedAction = ref<'lead.file.import' | 'lead.file.update' | undefined>(permissions.value.length > 0 ? permissions.value[0].key : undefined)

const back = () => {
	history.back()
}

const onCloseBanner = () => {
	isSaved.value = false
}
const updateModelValue = files => (payload.files = files)
const importUpdateLeadFile = async () => {
	if (selectedAction) {
		loading.value = true
		saving.value = true
		isSaved.value = false
		error.value = <any>{}

		try {
			const body = payload.files[0]?.file?.body
			if (selectedAction.value === 'lead.file.import') {
				await post({ name: selectedAction.value, params: { project: payload.project } }, { file: { body } })
			} else {
				await put({ name: selectedAction.value, params: { project: payload.project } }, { file: { body } })
			}
			payload.files = []
		} catch (err) {
			error.value = err
		}
		isSaved.value = !Boolean(error.value?.message)
		saving.value = false
		loading.value = false
	}
}

const fetchProjects = async () => {
	loading.value = true
	error.value = null
	try {
		const { data } = await get({ name: 'project', query: { sort: 'name', projection: 'name,label' } })
		projects.value = data.filter((project: Project) => user.value?.projects?.includes(project.name)) || []
	} catch (err) {
		error.value = err
	}
	loading.value = false
}

const downloadTemplate = async () => {
	if (selectedAction) {
		const type = selectedAction.value === 'lead.file.import' ? 'import' : 'update'
		loadingDownload.value = true
		try {
			const { data } = await get({ name: 'lead.generate.template', query: { type: templateType.value, templateType: type } })
			let base64Uri = ''
			switch (templateType.value) {
				case 'txt':
					base64Uri = `data:text/plain;base64,${data}`
					break
				case 'csv':
					base64Uri = `data:text/csv;base64,${data}`
					break
				case 'xls':
				case 'xlsx':
					base64Uri = `data:@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
					break
				default:
					base64Uri = `data:@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
					break
			}
			const xlsxBlob = await fetch(base64Uri).then(res => res.blob())
			const link = document.createElement('a')
			link.href = window.URL.createObjectURL(xlsxBlob)
			link.download = `LEADS_TEMPLATE_${dayjs().format('DD-MM-YYYY')}.${templateType.value}`
			link.click()
			link.remove()
		} catch (error) {}
		loadingDownload.value = false
	}
}

onMounted(async () => {
	await fetchProjects()
})
</script>
