<template>
	<div v-show="props.contract" class="col-span-full xl:col-span-4 bg-white rounded-sm border border-gray-200">
		<div class="border-b border-gray-200">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase p-3 border-b border-gray-200 mb-2">
				<icon name="ri-information-line" class="text-gray-400 mr-1" />
				Información
			</div>
			<div class="px-3 mb-3">
				<ul class="flex flex-wrap gap-4">
					<li class="flex items-center">
						<icon name="ri-radio-button-fill" class="text-blue-400 mr-1" />
						<span class="mr-1 font-bold" v-if="props.contract.rate"> {{ contract.cups }} </span>
						<sub class="text-xs">Cups</sub>
					</li>
					<li class="flex items-center">
						<icon name="ri-radio-button-fill" class="text-blue-400 mr-1" />
						<span class="mr-1 font-bold" v-if="props.contract.rate"> {{ parseRate(props.contract.rate) }} </span>
						<sub class="text-xs">Tarifa</sub>
					</li>
					<li v-if="props.contract.annualkWh" class="flex items-center">
						<icon name="ri-radio-button-fill" class="text-blue-400 mr-1" />
						<span class="mr-1 font-bold">{{ contract.annualkWh }}</span>
						<sub class="text-xs">/kWh</sub>
					</li>
				</ul>
				<div v-if="props.deactivateAt && user.permissions?.includes('show.deactivateAt')" class="flex items-center">
					<icon name="ri-radio-button-fill" class="text-blue-400 mr-1" />
					<span class="mr-1 font-bold">{{ dayjs(props.deactivateAt).format('DD/MM/YYYY HH:mm') }}</span>
					<sub class="text-xs">Fecha desactivación</sub>
				</div>
			</div>
		</div>
		<div class="px-4 pt-4 pb-3" v-if="props.contract.lastSupplierChange || contract.lastReading">
			<div class="flex items-center text-xs text-gray-800 font-semibold uppercase mb-2">
				<icon name="ri-list-check" class="text-gray-400 mr-1" />
				<div>Detalles</div>
			</div>
			<ul>
				<li v-if="props.contract.lastSupplierChange" class="flex items-center py-1">
					<icon name="ri-check-line" class="text-green-500 mr-1" />
					<div class="text-sm">
						Cambio comercializadora hace
						{{ dayjs().diff(contract.lastSupplierChange, 'month') }}
						meses
					</div>
				</li>
				<li v-if="props.contract.lastReading" class="flex items-center py-1">
					<icon name="ri-check-line" class="text-green-500 mr-1" />
					<div class="text-sm">
						Registro actualizado
						{{ dayjs(contract.lastReading).fromNow() }}
					</div>
				</li>
			</ul>
		</div>
		<Accordion title="Ver más" class="border-none">
			<ContractSummaryEnergyDetails :contract="props.contract" />
		</Accordion>
	</div>
</template>
<script setup lang="ts">
import { Contract } from '@/apps/go/interfaces/Contract'
import Accordion from '@/apps/go/components/Accordion.vue'
import ContractSummaryEnergyDetails from '@/apps/go/components/leads/ContractSummaryEnergyDetails.vue'
import { parseRate } from '../../utils/parseRate'
import { dayjs } from '@/services/dayjs'
import { user } from '@/user'
const props = defineProps<{ contract: Contract; deactivateAt?: Date }>()
</script>

<style lang="scss" scoped>
sub {
	bottom: -0.15em;
}
</style>
